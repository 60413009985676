<div class="nav-container">
  <div class="nav-content">
    <mat-nav-list>
      @for (item of menuItems; track item) {
      <app-nav-item [menu]="item" [index]="$index" [parentMenu]="null" [parentMenuIsOpen]="false"></app-nav-item>
      }
    </mat-nav-list>
  </div>
  <div class="nav-footer">
    <div class="api-documentation">
      <mat-icon class="material-symbols-outlined">data_object</mat-icon>
      <a>API Documentation</a>
    </div>
  </div>
</div>
